import { Link } from "gatsby"
import styled from "styled-components"

import React from "react"
import Subscribe from "../template/SubscribeForm.js"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  instaIcon,
  facebookIcon,
  pinterestIcon,
  twitterIcon,
  subscribeIcon,
} from "../utils/imageUpload"

const Footer = props => {
  return (
    <FooterStyle>
      <div className="container" style={{ margin: "0" }}>
        <div className="item" style={{ height: "100%" }}>
          <FunnyMan src={subscribeIcon} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="item contact-container">
          <div className="contact-div">
            <div style={{ width: "90%", margin: "0 auto" }}>
              <div>
                <Subscribe />
              </div>
              <p>
                FOR QUESTIONS, FEEDBACK, AND FAN MAIL FOR SKINNY & CHUBBY,
                PLEASE REACH OUT TO US AT{" "}
                <span style={{ textTransform: "uppercase" }}>
                  <a
                    style={{ textDecoration: "none", color: "var(--pink)" }}
                    href="mailto:hello@belgianboys.com"
                  >
                    hello@belgianboys.com
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="item link-div">
          <div className="links">
            <Link to="/">
              <li>HOME</li>
            </Link>
            <Link to="/faq">
              <li>FAQ</li>
            </Link>
            <Link to="/products">
              <li>PRODUCTS</li>
            </Link>
            <Link to="/shop">
              <li>SHOP</li>
            </Link>
            <Link to="/store-locator">
              <li>STORE LOCATOR</li>
            </Link>
            <Link to="/wholesale">
              <li>WHOLESALE</li>
            </Link>
          </div>
          <div className="icons">
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/belgianboys/"
            >
              <img className="icon" src={instaIcon} alt="" />
            </OutboundLink>
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/Belgianboys/"
            >
              <img className="icon" src={facebookIcon} alt="" />
            </OutboundLink>
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.pinterest.com/BelgianBoys/"
            >
              <img className="icon" src={pinterestIcon} alt="" />
            </OutboundLink>
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/belgianboys"
            >
              <img className="icon" src={twitterIcon} alt="" />
            </OutboundLink>
          </div>
        </div>
      </div>
      <p className="copyright">
        Copyright © {new Date().getFullYear()} B Boys LLC DBA Belgian Boys. All
        rights reserved. 11249, Brooklyn, New York
      </p>
    </FooterStyle>
  )
}
export default Footer

const FunnyMan = styled.img`
  height: 7.5%;
  width: 10%;

  @media (max-width: 769px) {
    height: 15%;
    width: 20%;
  }

  @media (max-width: 450px) {
    height: 24%;
    width: 32%;
  }
`

const FooterStyle = styled.div`
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .contact-container {
    justify-content: center;

    p {
      margin: 0.67em 0;
    }

    span {
      color: var(--pink);
    }
  }

  .link-div {
    flex-direction: column;
    width: 20% !important;
    justify-content: space-between;

    .links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70%;
    }
  }

  .item {
    width: 45%;
    // height: 25vh;
    display: flex;
  }

  background-color: var(--pink);

  .contact-div {
    background-color: var(--beige);
    color: var(--darkBlue);
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 30px 40px;
    border-radius: 25px;
    -webkit-box-shadow: 10px 10px 5px -2px rgba(148, 29, 88, 0.67);
    -moz-box-shadow: 10px 10px 5px -2px rgba(148, 29, 88, 0.67);
    box-shadow: 10px 10px 5px -2px rgba(148, 29, 88, 0.67);
    @media (max-width: 1080px) {
      padding: 15px 20px;
      width: 80%;
    }
    @media (max-width: 820px) {
      width: 85%;
      margin-right: 1rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    height: 100%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;

    :hover {
      text-decoration: underline;
      text-decoration-color: white;
    }
  }

  li {
    color: white;
    list-style-type: none;
    font-weight: bold;
  }

  .icons {
    display: flex;
  }
  .icon {
    width: 30px;
    margin-right: 15px;

    :hover {
      cursor: pointer;
    }
  }

  .copyright {
    color: white;
    text-align: center;
    width: 40%;
    margin: 0 auto;
  }

  @media (max-width: 769px) {
    .link-div {
      display: none;
    }

    .contact-container {
      width: 90vw;
      .contact-div {
        margin-right: 0px;
        width: 80%;
        p {
          font-size: 0.9rem;
          line-height: 150%;
          margin-top: 2rem;
        }
      }
    }

    .copyright {
      width: 60%;
    }
  }

  @media (max-width: 620px) {
    .contact-container {
      .contact-div {
        p {
          margin-top: 1rem;
        }
      }
    }
  }

  @media (max-width: 425px) {
    .contact-container {
      .contact-div {
        p {
          margin-top: 0.3rem;
          font-size: 0.7rem;
        }
      }
    }
  }

  .faq-link {
    color: var(--white);
    font-weight: bolder;
  }
`

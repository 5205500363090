import React from "react"
import { useCounter } from "../../hooks/useCounter"
import { useBundle } from "../../hooks/useBundle"
import { bundleTitle } from "../../lib/dealWithStrings"
import styled from "styled-components"

const BundleItem = ({
  el,
  addToBundler,
  bundleSize,
  bundleChoices,
  reallyRemove,
  index,
}) => {
  const { add, remove, counter } = useCounter(0)

  function reallyAdd() {
    // const product = { title: el.title, index }
    const product = el
    addToBundler({ product, add, counter })
  }

  // const title = bundleTitle(el)
  const { title } = el

  if (
    el.title === "Original Crêpes"

    // el.title === "Cookies & Cream CHEESECAKE" ||
    // el.title === "Cookie Butter CHEESECAKE"
  ) {
    return (
      <BundleListItem idx={el.idx} length={el.length} position={el.position}>
        <p className="outOfStock">{title}</p>

        <div className="button-div outOfStock">
          <button className="outOfStock">-</button>
          {counter}
          <button className="outOfStock">+</button>
        </div>
      </BundleListItem>
    )
  } else {
    return (
      <BundleListItem idx={el.idx} length={el.length} position={el.position}>
        {title}
        <div className="button-div">
          <button onClick={() => reallyRemove({ index, remove, counter })}>
            -
          </button>
          {counter}
          <button onClick={reallyAdd}>+</button>
        </div>
      </BundleListItem>
      // </li>
    )
  }
}

const BundleListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7.5px;
  margin-bottom: 7.5px;
  text-transform: capitalize;
  border-bottom: ${({ idx, length, position }) => {
    return idx ? "none" : length === position ? "1px solid lightgrey" : "none"
  }};

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 15px;
  }

  .outOfStock {
    color: lightgrey !important;
    text-decoration-line: line-through !important;

    p {
      color: lightgrey !important;
    }
    button {
      color: lightgrey !important;
      border: 2px solid lightgrey !important;
    }
  }
`

export default BundleItem

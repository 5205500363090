import React, { useState } from "react"
import { StyledBurger } from "../../styles/NavbarStyles"
import { useStore } from "../../context/TestContext"

const Burger = ({ isMenuOpen, toggleMenu }) => {
  return (
    <StyledBurger open={isMenuOpen} onClick={toggleMenu}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
export default Burger

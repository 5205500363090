import { useState, useEffect } from "react"
import { instaOne, instaTwo, instaThree, instaFour } from "../utils/imageUpload"

const defaultPics = [instaOne, instaTwo, instaThree, instaFour]

export function useInsta() {
  const [posts, setPosts] = useState(null)

  useEffect(() => {
    //   // axios.get("")

    //   //if error:
    function getRandomThree(defaultPics) {
      const randomizedPics = [...defaultPics]
      while (randomizedPics.length !== 4) {
        const index = Math.floor(Math.random() * defaultPics.length)
        randomizedPics.splice(index, 1)
      }

      return randomizedPics
    }

    setPosts(getRandomThree(defaultPics))
  }, [])

  return { posts }
}

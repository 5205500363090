import React from "react"
import {
  ShoppingCartWrapper,
  NonVisible,
} from "../../styles/ShoppingCartStyles"
import { useShoppingCart } from "../../hooks/"
import CheckoutDetailContainer from "./CheckoutDetailContainer"
import GoCheckout from "./GoCheckout"
import ApplyCoupon from "./ApplyCoupon"
import ShoppingCartHeader from "./ShoppingCartHeader"

const ShoppingCart = () => {
  const { isCartOpen, toggleCart } = useShoppingCart()

  return (
    <>
      <ShoppingCartWrapper openCart={isCartOpen}>
        <ShoppingCartHeader />
        <CheckoutDetailContainer />
        {/* <ApplyCoupon /> */}
        <GoCheckout />
      </ShoppingCartWrapper>
      <NonVisible onClick={toggleCart} openCart={isCartOpen} />
    </>
  )
}

export default ShoppingCart

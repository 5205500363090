import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { Button, Product, XButton } from "../../styles/ShoppingCartStyles"
import { useProductCart } from "../../hooks"
import BundleItem from "../template/BundleItem"

const ProductCart = props => {
  const { image, price, title, id, size, otherVariants, num, lineItem } = props
  // console.log("size:", size)
  // in props also is quantity, but we dont need it directly in the markup
  // console.log("BUNDLE PROPS", props)

  const {
    updateQuantity,
    setSize,
    sizeOfItem,
    qty,
    options,
    removeFromCart,

    isLoading,
    removeBundle,
    isBundle,
    isMainBundle,
  } = useProductCart(props)

  //from our useProduct Cart we do the useEffects and everything necessary. Just cleaner

  return (
    <Product className="flex" isBundle={isBundle}>
      <p>
        {isBundle
          ? //  num + 1 + "."
            ""
          : ""}
      </p>
      <Image fluid={image} className="productImg" />
      <div className="info-div flex flex-col">
        <div className="flex">
          <p className="productName">{title}</p>
          {isBundle ? null : (
            <XButton
              remove
              color="var(--orangeText)"
              bg="transparent"
              disabled={isLoading}
              onClick={() =>
                isMainBundle ? removeBundle(props) : removeFromCart(id)
              }
            >
              X
            </XButton>
          )}
        </div>
        <div className="flex">
          <div>
            {isBundle || isMainBundle ? (
              <p>Qty: {qty}</p>
            ) : (
              <p>
                Qty:
                <select
                  className="form-control__select"
                  aria-label="Quantity:"
                  value={qty}
                  onChange={e => {
                    updateQuantity(e)
                  }}
                >
                  {options.map(el => (
                    <option key={el} data-label={`Qty: ${el}`} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
              </p>
            )}
          </div>
          {size && <p style={{ textTransform: "capitalize" }}>{size}</p>}
          {/* {size && (
            <div>
              <p>
                Size:
                <select
                  className="form-control__select"
                  aria-label="Quantity:"
                  value={sizeOfItem}
                  onChange={e => {
                    setSize(e)
                  }}
                >
                  {otherVariants.map(el => (
                    <option
                      key={el.title}
                      data-label={`Size: ${el.title}`}
                      value={el.title}
                    >
                      {el.title}
                    </option>
                  ))}
                </select>
              </p>
            </div>
          )} */}
          {!isBundle && <p>${price}</p>}
        </div>
      </div>
    </Product>
  )
}

export default ProductCart

import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
}

.product-link-wrapper {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

:root {
   --pink: #e81f76;
   --beige: #ffeddb;
   --white: #ffffff;
   --black: rgb(1, 1, 1);
   --lightBlue:rgb(0, 167, 225);
   --clickOut:rgba(1, 1, 1, 0.1);
   --mainMargin: 8rem;
   --darkBlue: #0e0048;
   --bigScreen: 1200px;
   --bigWidth: 936px;
   --laptopScreen: 1000px;
   --laptopWidth: 800px;
   --smallLaptopScreen: 900px;
   --smallLaptopWidth: 650px;
   --tabletScreen: 700px;
   --tabletWidth:580px;
   --phoneScreen:600px;
   --phoneWidth: 85vw;
   --huge: 1150px;
   --hugeInNav: 986px;
   --mainMobileMargin: 6rem;

 
 }
 
 html {
  //  font-family:  sans-serif;
   -ms-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
 }
 body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x:hidden;
 }

 .outOfStock {
   opacity: 0.4;
   :hover {
     cursor: auto !important;
   }
 }

 
 button:focus {
   outline: none;
 }

 button:active {
  transform: translateY(2px);
}


 
 button:hover {
   cursor: pointer;
 }`

//Navbar
import belgiumBoysLogo from "../images/logo.png"
import shoppingCart from "../images/nav_assets/cart.png"

//Home
import landing from "../images/Home/Landing.jpg"
import landingText from "../images/Home/landingText.png"
import storyOne from "../images/Home/HP-Gif1.gif"
import storyTwo from "../images/Home/HP-Gif2.gif"
import storyThree from "../images/Home/HP-Gif3.gif"
import storyLineOne from "../images/Home/SC-Story-Path.png"
import storyLineTwo from "../images/Home/SC-Story-Path2.png"
import infoLanding from "../images/Home/HomePageBanner2.jpg"

import infoTitle from "../images/Home/infoTitle.png"
import info1 from "../images/Home/1-NON-GMO-01.png"
import info2 from "../images/Home/2-NON-ARTIFICIAL.png"
import info3 from "../images/Home/3-SUITABLE-FOR-VEGETARIANS.png"
import info4 from "../images/Home/4-NO-CORN-SYRUP.png"
import info5 from "../images/Home/5-KOSHER-CERTIFIED.png"
import info6 from "../images/Home/6-NUT-FREE.png"

//Footer
import instaIcon from "../images/Home/SOCIAL-INSTA.png"
import facebookIcon from "../images/Home/SOCIAL-FB.png"
import pinterestIcon from "../images/Home/SOCIAL-PINTEREST.png"
import twitterIcon from "../images/Home/SOCIAL-TWITTER.png"
import subscribeIcon from "../images/Home/subscribeIcon.png"

//Gallery

import Dessert from "../images/Gallery/Desert.png"
import Breakfast from "../images/Gallery/Breakfast.png"
import Cookies from "../images/Gallery/Cokies.png"

import forward from "../images/forward.png"
import backward from "../images/backward.png"

//Social Mission

import snap from "../images/SocialMission/SNAP-A-PIC.png"
import banner from "../images/SocialMission/BANNER.png"

//Instagram

import instaOne from "../images/Instagram/1.jpg"
import instaTwo from "../images/Instagram/2.jpg"
import instaThree from "../images/Instagram/3.jpg"
import instaFour from "../images/Instagram/4.jpg"

//Shop

import iconBanner1 from "../images/Shop/Shop-Page-Banner-Illustration-1.png"
import iconBanner2 from "../images/Shop/Shop-Page-Banner-Illustration-2.png"
import iconBanner3 from "../images/Shop/Shop-Page-Banner-Illustration-3.png"

//popBar

import IconPopbar from "../images/Popbar/Icon.png"
import PopBar from "../images/Popbar/PopBar_Banner.png"
import StroopImage from "../images/Popbar/StroopImage.png"
import PopbarGif from "../images/Popbar/popbarGif.gif"
import closeUp1 from "../images/Popbar/PopBar_Image_Closeup.png"
import closeUp2 from "../images/Popbar/PopBar_Image_Closeup_2.png"
import closeUp3 from "../images/Popbar/PopBar_Image_Closeup_3.png"

//WaffleParty
import waffleGif from "../images/WaffleParty/S&C.gif"
import waffleDancing from "../images/WaffleParty/Waffle-Dancing.gif"
import topWaffel from "../images/WaffleParty/Top_Waffles_Image.png"
import waffelBottom from "../images/WaffleParty/Bottom_Waffle_Image.png"
import twoWaysWaffel from "../images/WaffleParty/Two-ways-to-win.gif"
import calloutWaffel from "../images/WaffleParty/Callouts.png"

// MAP STUFF

import phone from "../images/Map/phone.png"
import web from "../images/Map/www.png"
import mapMarker from "../images/Map/marker.png"

// then within your component
import AprHover from "../images/SocialMission/Мake The World Sweeter/1 April-Hover.png"
import Apr from "../images/SocialMission/Мake The World Sweeter/1 April.jpg"
import MarHover from "../images/SocialMission/Мake The World Sweeter/2 March-Hover.png"
import Mar from "../images/SocialMission/Мake The World Sweeter/2 March.jpg"
import FebHover from "../images/SocialMission/Мake The World Sweeter/3 February-Hover.png"
import Feb from "../images/SocialMission/Мake The World Sweeter/3 February.jpg"
import JanHover from "../images/SocialMission/Мake The World Sweeter/4 January-Hover.png"
import Jan from "../images/SocialMission/Мake The World Sweeter/4 January.jpg"
import DecHover from "../images/SocialMission/Мake The World Sweeter/5 December-Hover.png"
import Dec from "../images/SocialMission/Мake The World Sweeter/5 December.jpg"
import NovHover from "../images/SocialMission/Мake The World Sweeter/6 November-Hover.png"
import Nov from "../images/SocialMission/Мake The World Sweeter/6 November.jpg"
import OctHover from "../images/SocialMission/Мake The World Sweeter/7 October-Hover.png"
import Oct from "../images/SocialMission/Мake The World Sweeter/7 October.jpg"
import SepHover from "../images/SocialMission/Мake The World Sweeter/8 September-Hover.png"
import Sep from "../images/SocialMission/Мake The World Sweeter/8 September.jpg"

import charity1 from "../images/SocialMission/CharitiesLogos/1.png"
import charity2 from "../images/SocialMission/CharitiesLogos/2.png"
import charity3 from "../images/SocialMission/CharitiesLogos/3.png"
import charity4 from "../images/SocialMission/CharitiesLogos/4.png"
import charity5 from "../images/SocialMission/CharitiesLogos/5.png"
import charity6 from "../images/SocialMission/CharitiesLogos/6.png"
import charity7 from "../images/SocialMission/CharitiesLogos/7.png"
import charity8 from "../images/SocialMission/CharitiesLogos/8.png"
import charity9 from "../images/SocialMission/CharitiesLogos/9.png"
import charity10 from "../images/SocialMission/CharitiesLogos/10.png"
import charity11 from "../images/SocialMission/CharitiesLogos/11.png"
import charity12 from "../images/SocialMission/CharitiesLogos/12.png"
import charity13 from "../images/SocialMission/CharitiesLogos/13.png"
import charity14 from "../images/SocialMission/CharitiesLogos/14.png"
import charity15 from "../images/SocialMission/CharitiesLogos/15.png"
import charity16 from "../images/SocialMission/CharitiesLogos/16.png"
import charity17 from "../images/SocialMission/CharitiesLogos/17.png"
import charity18 from "../images/SocialMission/CharitiesLogos/18.png"
import charity19 from "../images/SocialMission/CharitiesLogos/19.png"

const allCharities = [
  charity1,
  charity2,
  charity3,
  charity4,
  charity5,
  charity6,
  charity7,
  charity8,
  charity9,
  charity10,
  charity11,
  charity12,
  charity13,
  charity14,
  charity15,
  charity16,
  charity17,
  charity18,
  charity19,
]

const charityMoustaches = [
  {
    pic: Apr,
    hover: AprHover,
  },
  {
    pic: Mar,
    hover: MarHover,
  },
  {
    pic: Feb,
    hover: FebHover,
  },
  {
    pic: Jan,
    hover: JanHover,
  },
  {
    pic: Dec,
    hover: DecHover,
  },
  {
    pic: Nov,
    hover: NovHover,
  },
  {
    pic: Oct,
    hover: OctHover,
  },
  {
    pic: Sep,
    hover: SepHover,
  },
]
//Map

//Charity Page
const charityLogos = require.context(
  "../images/SocialMission/CharitiesLogos",
  true
)
const charityImgPath = name => charityLogos(name, true)

export {
  calloutWaffel,
  twoWaysWaffel,
  waffelBottom,
  topWaffel,
  waffleDancing,
  waffleGif,
  closeUp1,
  closeUp2,
  closeUp3,
  IconPopbar,
  PopBar,
  StroopImage,
  PopbarGif,
  landingText,
  infoLanding,
  storyLineOne,
  storyLineTwo,
  storyThree,
  storyTwo,
  storyOne,
  belgiumBoysLogo,
  landing,
  shoppingCart,
  info1,
  info2,
  info3,
  info4,
  info5,
  info6,
  infoTitle,
  instaIcon,
  facebookIcon,
  pinterestIcon,
  twitterIcon,
  subscribeIcon,
  Dessert,
  Breakfast,
  Cookies,
  forward,
  backward,
  snap,
  banner,
  instaOne,
  instaTwo,
  instaThree,
  instaFour,
  iconBanner1,
  iconBanner2,
  iconBanner3,
  charityImgPath,
  charityMoustaches,
  allCharities,
  phone,
  web,
  mapMarker,
}

import { useState } from "react"

export function useCounter(val) {
  const [counter, setCounter] = useState(val)

  const add = () => {
    setCounter(c => c + 1)
  }
  const remove = () => {
    if (counter > val) setCounter(c => c - 1)
  }

  return { counter, add, remove }
}

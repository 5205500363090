import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStore } from "../context/TestContext"
import { useSubscribe, useDisplaySize } from "../hooks/"

const Subscribe = ({ message, about }) => {
  const { subscribe } = useStore()

  const { email, handleSubmit, handleChange } = useSubscribe(subscribe)

  const { display } = useDisplaySize()

  const placeholder =
    display === "mobile" ? "Email" : "Enter your email here..."

  return (
    <div>
      <SubscribeForm
        message={message}
        about={about}
        style={{ margin: "0" }}
        className="emailQuery"
        onSubmit={handleSubmit}
      >
        <div className="email-input">
          <input
            placeholder={placeholder}
            type="email"
            value={email}
            onChange={handleChange}
          />
        </div>
        <input type="submit" value="Subscribe" />
      </SubscribeForm>
    </div>
  )
}

const SubscribeForm = styled.form`
  display: flex !important;
  justify-content: center;

  input {
    background-color: transparent;
    border: 1px solid var(--darkBlue);
    color: var(--darkBlue);
    padding: 15px 25px;
    font-weight: 600;
    font-size: 13px;
    -webkit-appearance: none;

    @media (max-width: 769px) {
      padding: 10px 10px;
      font-size: 12px;
    }
  }

  input:focus {
    outline: none;
    color: var(--darkBlue);
  }

  input[type="email"] {
    border-radius: none;
    width: 100%;
  }

  input[type="submit"] {
    background-color: var(--darkBlue);
    color: var(--white);
    text-transform: uppercase;
    font-weight: bold;
  }
  input[type="submit"]:hover {
    background-color: var(--pink);
    color: var(--white);
    cursor: pointer;
  }

  .emailQuery {
    display: flex;
    width: 100%;
  }

  .email-input {
    width: 100%;
  }
`

export default Subscribe

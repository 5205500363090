import { useState, useEffect } from "react"

export function useRelatedProducts({ data, product }) {
  const [relatedProducts, setRelatedProducts] = useState([])

  useEffect(() => {
    function getRelatedProducts(shopifyCollection) {
      const arr = shopifyCollection.products.filter(
        el => el.handle !== product.handle
      )

      const randomizedRecommendations = [...arr]
      while (randomizedRecommendations.length !== 4) {
        const index = Math.floor(Math.random() * arr.length)
        randomizedRecommendations.splice(index, 1)
      }

      return randomizedRecommendations
    }

    setRelatedProducts(getRelatedProducts(data.shopifyCollection))
  }, [])

  return { relatedProducts }
}

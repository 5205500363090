import React from "react"
import { useShoppingCart } from "../../hooks"
import {
  Container,
  ShoppingHeader,
  Button,
} from "../../styles/ShoppingCartStyles"

function ShoppingCartHeader() {
  const { toggleCart } = useShoppingCart()

  return (
    <Container className="flex center">
      <ShoppingHeader>
        <h3>Shopping Cart</h3>
        <Button
          exit
          color="var(--darkBlue)"
          bg="transparent"
          onClick={toggleCart}
          // onClick={() => {
          //   console.log("Clicked?")
          // }}
        >
          Exit
        </Button>
      </ShoppingHeader>
    </Container>
  )
}

export default ShoppingCartHeader

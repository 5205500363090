// import { Link } from "gatsby"
// import styled from "styled-components"

import React from "react"
// import Navbar from "./Navbar"
import { Link } from "gatsby"
import { belgiumBoysLogo, shoppingCart } from "../../utils/imageUpload"
import LogoSection from "./LogoSection"
import MenuLinks from "./MenuLinks"
import CartSection from "./CartSection"
import styled from "styled-components"
import { BurgerWrapper } from "../../styles/NavbarStyles"
import Burger from "./Burger"
import Menu from "./Menu"
import { useMenuOpen } from "../../hooks/"

export const websiteMap = [
  {
    link: "/shop",
    where: "Shop",
  },
  {
    link: "/products",
    where: "Discover",
  },
  {
    link: "/social-mission",
    where: "Rock That Stash",
  },

  {
    link: "/store-locator",
    where: "Store Locator",
  },
]

const Nav = ({ output }) => {
  const { isMenuOpen, toggleMenu } = useMenuOpen()

  return (
    <>
      <NavBar>
        <div className="wrapper">
          <div className="base">
            <LogoSection />
            <MenuLinks />
            <CartSection />
            <BurgerWrapper>
              <Burger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
              <Menu open={isMenuOpen} />
            </BurgerWrapper>
          </div>
        </div>
        {output && (
          <SubscribeMessage output={output}>{output}</SubscribeMessage>
        )}
      </NavBar>
    </>
  )
}

const SubscribeMessage = styled.div`
  width: 100vw;
  background: ${props =>
    props.output === "Thank you for subscribing!"
      ? "var(--beige)"
      : "var(--darkBlue)"};

  color: ${props =>
    props.output === "Thank you for subscribing!"
      ? "var(--darkBlue)"
      : "white"};

  text-align: center;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadeinout 4s linear forwards;
  -webkit-animation: fadeinout 4s linear forwards;

  @-webkit-keyframes fadeinout {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }
`
const NavBar = styled.nav`
  height: var(--mainMargin);
  background: var(--pink);
  position: fixed;
  width: 100vw;
  z-index: 3;
  top: 0;
  @media (max-width: 769px) {
    height: var(--mainMobileMargin);
  }
  .wrapper {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    .base {
      width: 100%;
      height: 80%;
      max-width: var(--hugeInNav);
      margin: 0 auto;
      display: grid;
      grid-template-columns: 9rem 5fr auto;
      justify-content: space-between;
      align-content: end;
      grid-template-rows: 100%;
      column-gap: 35px;
      grid-template-areas: "logo links cart";
      @media (max-width: 1200px) {
        max-width: var(--bigWidth);
      }

      @media (max-width: 1000px) {
        max-width: var(--laptopWidth);
      }

      @media (max-width: 900px) {
        max-width: var(--smallLaptopWidth);
        column-gap: 10px;
        grid-template-columns: 8rem auto auto;
      }

      @media (max-width: 769px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        align-content: center;
      }

      @media (max-width: 700px) {
        max-width: var(--tabletWidth);
      }

      @media (max-width: 600px) {
        max-width: var(--phoneWidth);
        /* grid-template-columns: 2fr 4fr 2fr; */
      }

      @media (max-width: 400px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        max-width: 80vw;
      }
    }
  }
`

// <button
//                   css={`
//                     width: 100%;
//                     border: none;
//                     position: relative;
//                     background: none;
//                     cursor: pointer;
//                     /* height: 10 */

//                     img {
//                       height: 100%;
//                     }
//                   `}
//                 >
//                   <img src={shoppingCart} alt="" />
//                 </button>

export default Nav

import React from "react"
import { shoppingCart } from "../../utils/imageUpload"
import styled from "styled-components"
import { useStore } from "../../context/TestContext"
import { Link } from "gatsby"

const CartSection = () => {
  const data = useStore()
  const { state, toggleCart } = data
  // console.log("state:", state)

  const total =
    state?.checkout?.lineItems?.reduce((acc, val) => acc + val.quantity, 0) || 0
  // const totalItems = state.cart.reduce((acc, { quantity }) => acc + quantity, 0)
  return (
    <Cart>
      <Link style={{ textDecoration: "none" }} to="/wholesale">
        <div className="wholesale">Wholesale</div>
      </Link>
      <ButtonWrapper>
        <button onClick={toggleCart}>
          {total ? <div className="quantity">{total}</div> : null}
        </button>
      </ButtonWrapper>
    </Cart>
  )
}

const Cart = styled.div`
  grid-area: cart;
  // width: 4rem;
  height: 85%;
  align-self: end;
  display: flex;
  flex-direction: column;
  /* align-self: end; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1.5fr;
  row-gap: 10px;
  grid-template-areas:
    "wholesale"
    "cartIcon";

  .wholesale {
    width: 100%;
    text-align: end;
    color: white;
    grid-area: wholesale;
  }

  @media (max-width: 769px) {
    grid-template-rows: 1fr;
    grid-template-areas: "cartIcon";
    height: 100%;
    width: 100%;
    order: 2;
    width: auto;

    .wholesale {
      display: none;
    }
  }

  @media (max-width: 400px) {
  }
`

const ButtonWrapper = styled.div`
   grid-area: cartIcon;
   height: 100%;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr;
   align-items: center;
   button {
      position: relative;
      width: 100%;
      background: none;
      border: none;
      height: 100%;
      background-image:url('${shoppingCart}');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      @media (max-width:995px) {
        background-position-x:15px
      }

      @media (max-width:769px) {
        height:40%;
        background-position-x:0;
        background-position:right;
        width:40px
      }

      @media (max-width:450px) {
      }

      .quantity {
        color: var(--darkBlue);
        background: rgba(255, 255, 255, 0.8);
        font-weight: 600;
        border-radius: 15px;
        text-align: center;
        font-size: 1rem;
        height: 30px;
        line-height: 30px;
        width: 30px;
        position: absolute;
        right: -15px;
        top: -10px;
        @media (max-width: 1000px) {
          right: -15px;
        }

      @media (max-width:769px) {
          top:-15px;
          right: -13px;
          /* height:15px;width:15px; */
        }

        @media (max-width:450px) {
          right:-15px;
        }
      }
    }
`

export default CartSection

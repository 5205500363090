import { useState } from "react"
import { useEffect } from "react"
import { useStore } from "../context/TestContext"

export function useProductCart(data) {
  // console.log("data:", data)

  const {
    quantity,
    size,
    id,
    price,
    title,
    image,
    otherVariants,
    isBundle = "",
    isMainBundle = false,
  } = data
  const {
    updateSize,
    updateCart,
    removeFromCart,

    state: { isLoading, cart },
    removeBundle,
  } = useStore()

  // console.log("cart:", cart, [...new Set(cart.map(({ id }) => id))])

  const [qty, setQty] = useState(quantity)
  const [sizeOfItem, setSizeOfItem] = useState(size)
  // console.log("sizeOfItem:", sizeOfItem)

  useEffect(() => {
    setQty(quantity)
  }, [quantity])

  function updateQuantity({ target: { value } }) {
    setQty(parseFloat(value))
    updateCart(id, parseFloat(value))
  }

  function setSize({ target: { value } }) {
    setSizeOfItem(value)
    const args = {
      price,
      title,
      image,
      quantity: qty,
      variants: otherVariants,
      size: value,
    }

    // console.log("Called?")
    updateSize(id, args)
  }

  const options = [...Array(qty + 10).keys()].map(e => e + 1)

  return {
    updateQuantity,
    setSize,
    sizeOfItem,
    qty,
    options,
    removeFromCart,

    isLoading,
    isMainBundle,
    removeBundle,
    isBundle,
  }
}

// const [qty, setQty] = useState(quantity)
// const [sizeOfItem, setSizeOfItem] = useState(size)
//   const { updateCart, removeFromCart } = useStore()
// const { updateSize, removeFromCart, updateCart } = useStore()

// useEffect(() => {
//   setQty(quantity)
// }, [quantity])

// const options = [...Array(qty + 10).keys()].map(e => e + 1)

// function updateQuantity({ target: { value } }) {
//   // setQty(e.target.value)
//   setQty(parseFloat(value))
//   updateCart(id, parseFloat(value))
// }

// function setSize({ target: { value } }) {
//   setSizeOfItem(value)

//   const args = {
//     price,
//     title,
//     image,
//     quantity: qty,
//     variants: otherVariants,
//     size: value,
//   }
//   updateSize(id, args)
// }

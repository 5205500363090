import React from "react"
import { useShoppingCart } from "../../hooks"
import { Container, Button } from "../../styles/ShoppingCartStyles"
import { useStore } from "../../context/TestContext"
import ReactPixel from "react-facebook-pixel"

function GoCheckout() {
  const { paymentCheckout, url, onError, isCartEmpty } = useShoppingCart()
  const {
    state: { cart },
  } = useStore()

  // const pixel = e => {
  //   e.preventDefault()
  //   const cartContents = cart.map(el => {
  //     return { title: el.title, price: el.price, quantity: el.quantity }
  //   })
  //   ReactPixel.track("InitiateCheckout", { contents: cartContents })

  //   window.location = url
  // }
  return (
    <Container className="flex center">
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        {isCartEmpty ? (
          <a style={{ width: "100%" }}>
            <Button
              disabled={isCartEmpty}
              color={"var(--white)"}
              bg={"var(--darkBlue)"}
              // onClick={paymentCheckout}
              checkout
            >
              Checkout
            </Button>
          </a>
        ) : (
          <a
            // onClick={pixel}
            style={{ width: "100%" }}
            href={url}
          >
            <Button
              disabled={isCartEmpty}
              color={"var(--white)"}
              bg={"var(--darkBlue)"}
              // onClick={paymentCheckout}
              checkout
            >
              Checkout
            </Button>
          </a>
        )}

        {onError && (
          <p style={{ marginTop: "1rem", fontSize: "1.1rem" }}>{onError}</p>
        )}
      </div>
    </Container>
  )
}

export default GoCheckout

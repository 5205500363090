import React from "react"
import { Link } from "gatsby"
import { StyledMenu } from "../../styles/NavbarStyles"
import { websiteMap } from "./nav"
import { useStore } from "../../context/TestContext"

const Menu = ({ open }) => {
  const { state } = useStore()

  return (
    <StyledMenu open={open}>
      <div>
        <Link activeClassName="activeSideNav" to={"/"}>
          home
        </Link>
        {websiteMap.map(el => (
          <Link activeClassName="activeSideNav" key={el.link} to={el.link}>
            {el.where}
          </Link>
        ))}
      </div>
    </StyledMenu>
  )
}

export default Menu

import React from "react"
// import { websiteMap } from "./Navbar"
import { Link } from "gatsby"
import styled from "styled-components"
import { websiteMap } from "./nav"

const MenuLinks = () => {
  return (
    <LinkSection>
      {websiteMap.map(el => (
        <li key={el.where}>
          <Link to={el.link} activeClassName="activeNav">
            {el.where}
          </Link>
        </li>
      ))}
    </LinkSection>
  )
}

const LinkSection = styled.div`
  list-style: none;
  grid-area: links;
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: end;
  justify-content: space-evenly;
  grid-gap: 10px;
  font-size: 1.2rem;

  .activeNav {
    padding-bottom: 5px;
    font-weight: 800;
    color: var(--darkBlue);
  }
  li {
    text-align: center;

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      &:hover {
        padding-bottom: 5px;
        /* color: var(--darkBlue); */
        color: #0e0048;
        @media (max-width: 769px) {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 13px;
    grid-gap: 1rem;
    letter-spacing: 0.02rem;
  }

  @media (max-width: 769px) {
    display: none;
  }
`

export default MenuLinks

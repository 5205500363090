import React from "react"
import {
  Overview,
  ProductWrapper,
  Container,
} from "../../styles/ShoppingCartStyles"
import { useShoppingCart } from "../../hooks"
import ProductCart from "./ProductCart"
import { Link } from "gatsby"
import BundleInCart from "../BundleInCart/BundleInCart"

function CheckoutDetailContainer() {
  const {
    cart,
    discountCode,
    removeCoupon,
    isShipping,
    discountInfo,
    total,
    toggleCart,
    bundles,
  } = useShoppingCart()
  // console.log("bundles:", total)

  // Cart
  // Product 1
  // Product 2

  // Cart
  // THIS GETS ADDED DYNAMICALLY VISIBLE BUT NOT AS A PRODUCT
  // Bundle
  // Product
  /* console.log(
    bundles.length,
    Object.keys(bundles).length === 0 && bundles.constructor === Object,
    cart.length,
    "CHECKOUT"
  ) */
  return (
    <Container className="flex center">
      <div className="flex flex-col">
        <Overview>
          <ProductWrapper className="flex flex-col">
            {cart.length === 0 &&
            Object.keys(bundles).length === 0 &&
            bundles.constructor === Object ? (
              <p>Your shopping cart is empty.</p>
            ) : (
              cart.map((product, i) => (
                <ProductCart key={i} {...product} product={product} />
              ))
            )}
            {/* {Object.keys(bundles).length === 0
              ? null
              : Object.entries(bundles).map(el => <BundleInCart {...el} />)} */}
            {Object.keys(bundles).length !== 0
              ? Object.entries(bundles).map((el, i) => {
                  // console.log(el, "ELEMENT")
                  return (
                    <>
                      <ProductCart key={i} {...el[1]} product={el[1]} />
                      {el[1].products.map((product, i) => (
                        <>
                          <ProductCart
                            num={i}
                            key={i}
                            {...product}
                            product={product}
                          />
                        </>
                      ))}
                    </>
                  )
                  {
                    /* (
                     {el[1].products.map((product, i) => (
                       <ProductCart key={i} {...product} product={product} />
                     ))}
                    ) */
                  }
                })
              : null}
          </ProductWrapper>
          <div className="flex total dotted-line">
            {discountCode ? (
              <>
                <p>
                  Discount: {discountCode.toUpperCase()}{" "}
                  <button
                    className="remove-coupon"
                    onClick={() => removeCoupon(discountCode)}
                  >
                    X
                  </button>
                </p>
                <div className="flex">
                  <>
                    {isShipping ? (
                      <p>Free Shipping</p>
                    ) : (
                      <p>-{discountInfo?.value?.percentage}% </p>
                    )}
                  </>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="flex total">
            <h3>Total</h3>
            <h4>${total}</h4>
          </div>
          <p onClick={toggleCart}>
            Looking for more?
            <Link to="/shop">Continue Shopping</Link>
          </p>
        </Overview>
      </div>
    </Container>
  )
}

export default CheckoutDetailContainer

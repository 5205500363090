import { useState } from "react"

export function useSubscribe(subscribeFunc) {
  const [email, setEmail] = useState("")

  function handleChange(e) {
    setEmail(e.target.value)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const res = await subscribeFunc(email)
    if (res) {
      setEmail("")
    }
  }

  return { email, handleSubmit, handleChange }
}

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "./footer"
import Header from "./header"

import "./layout.css"
import Nav from "./navbar/nav.js"
import { GlobalStyle } from "../styles/GlobalStyle"
import ShoppingCart from "./ShoppingCart/ShoppingCart"
import { useState } from "react"
import { useStore } from "../context/TestContext"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Loadable from "react-loadable"

function Loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else {
    return <div>Loading...</div>
  }
}

const LoadablePixel = Loadable({
  loader: () => import("../template/ReactPixel"),
  loading: Loading,
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { state } = useStore()
  const output = state?.message
    ?.replace(/[0-9]/g, "")
    ?.replace(/-/g, "")
    ?.trim()

  return (
    <>
      <GlobalStyle />
      <Nav output={output} />

      <ShoppingCart />
      <LayoutBody>
        <main>{children}</main>
        {typeof window !== "undefined" && <LoadablePixel />}

        <Footer />
      </LayoutBody>
    </>
  )
}

const LayoutBody = styled.main`
  margin-top: var(--mainMargin);

  @media (max-width: 769px) {
    margin-top: var(--mainMobileMargin);
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { useState, useEffect } from "react"

export function useDisplaySize() {
  const [display, setDisplay] = useState("desktop")

  useEffect(() => {
    function displaySize() {
      const screen = window.innerWidth
      if (screen > 960) {
        return "desktop"
      } else if (screen < 769 && screen > 690) {
        return "tablet"
      } else if (screen < 690) {
        return "mobile"
      }
    }
    const displayOfUser = displaySize()
    setDisplay(displayOfUser)
  }, [])

  useEffect(() => {
    function changeDisplay() {
      const screen = window.innerWidth
      if (screen > 960) {
        setDisplay("desktop")
      } else if (screen < 960 && screen > 600) {
        setDisplay("tablet")
      } else if (screen < 600) {
        setDisplay("mobile")
      }
    }

    window.addEventListener("resize", changeDisplay)
    return () => window.removeEventListener("resize", changeDisplay)
  }, [display])

  return { display }
}

import React from "react"
import { belgiumBoysLogo } from "../../utils/imageUpload"

import { Link } from "gatsby"
import styled from "styled-components"

const LogoSection = () => {
  return (
    <LogoArea>
      <Link to="/">
        <img src={belgiumBoysLogo} alt="" />
      </Link>
    </LogoArea>
  )
}

const LogoArea = styled.div`
  height: 100%;
  grid-area: logo;
  /* width: 100%; */

  /* width: 100%; */

  a {
    display: flex;

    align-items: flex-end;
    height: 100%;
    /* width: 100%; */

    img {
      // height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    margin-right: 0;
    height: 85%;
    align-self: flex-end;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      /* width: 100%; */

      img {
        width: inherit;
        height: 100%;
      }
    }
  }

  @media (max-width: 769px) {
    align-self: center;
    justify-self: center;
    height: 100%;
    order: 1;
  }

  @media (max-width: 400px) {
  }
`

export default LogoSection

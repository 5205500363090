import { useState } from "react"
import { useCounter } from "."
import { useStore } from "../context/TestContext"
import ReactPixel from "react-facebook-pixel"

export function useBundle({ size, product, setPopup, price }) {
  const { addToCart, addToBundle } = useStore()
  // console.log(price, "PRICE")

  const options = [...Array(size).keys()].map(() => false)

  const [bundleChoices, setBundleChoices] = useState([])
  const [mainBundlePrice] = useState(parseInt(price))
  const [warn, setWarning] = useState("")
  const [progressBar, setProgressBar] = useState(options)

  async function addBundleToCart() {
    // return
    if (bundleChoices.length !== size) {
      return updateWarning(
        `You must choose ${size} items in order to add the bundle to cart`,
        2500
      )
    }
    // console.log("PRODUCTS SLECTED", bundleChoices)

    // console.log("price INSIDE DAS FUNKTION:", mainBundlePrice)
    setPopup(false)
    await addToBundle(bundleChoices, product, mainBundlePrice)
    ReactPixel.track("AddToCart", {
      content_name: product.title,
      // contents: pixelBundle,
      value: product.variants[0].price,
      currency: "USD",
      num_items: 1,
    })
    return
    const {
      title,
      variants,
      variants: [{ price, shopifyId }],
    } = product

    const image = product?.images?.[0]?.localFile?.childImageSharp?.fluid

    const variantId = product?.variants?.[0]?.shopifyId

    // const customAttributes = { key: "products bundled", value: cleanBundle }
    const args = {
      title,
      price,
      quantity: 1,
      image,
      variantId: shopifyId,
      variants,
      // customAttributes,
    }
    const add = await addToCart(args)

    setPopup(false)
  }

  function addToBundler({ product, add, counter }) {
    // console.log("product:", product)
    if (counter >= size || bundleChoices.length >= size) {
      return updateWarning(
        `You can't add more than ${size} items to this bundle`
      )
    }

    const newBundleChoice = [...bundleChoices, product]
    // console.log("newBundleChoice:", newBundleChoice)
    // console.log(newBundleChoice, "Arr containing fulll shopify product items")
    add()
    setBundleChoices(newBundleChoice)
    updateProgressBar(newBundleChoice)
  }

  function updateWarning(msg, time = 1500) {
    setWarning(msg)
    return setTimeout(() => {
      setWarning("")
    }, time)
  }

  function reallyRemove({ index, remove, counter }) {
    if (counter <= 0) {
      return
    }
    const arr = [...bundleChoices]

    const newArrIndex = arr.findIndex(el => {
      return el.index === index
    })

    arr.splice(newArrIndex, 1)

    remove()
    setBundleChoices(arr)
    updateProgressBar(arr)
  }

  function updateProgressBar(arr) {
    const newProgressBar = [...progressBar].map((el, i) =>
      i >= arr.length ? false : true
    )
    setProgressBar(newProgressBar)
  }

  return {
    warn,
    bundleChoices,
    addToBundler,
    reallyRemove,
    addBundleToCart,
    progressBar,
  }
}

import { useState } from "react"
import { useEffect } from "react"

export function useShop(data) {
  const listOfProducts = data.shopifyCollection.products
  const [list, setList] = useState(listOfProducts)
  const [types, setTypes] = useState([])
  const [select, setSelect] = useState("1")
  const [search, setSearch] = useState("")

  useEffect(() => {
    function getAllProductTypes(allShopItems) {
      const typesArr = allShopItems.map(product => product.productType)

      return [...new Set(typesArr)]
    }

    setTypes(getAllProductTypes(listOfProducts))
  }, [])

  function handleChange(e) {
    setSearch(e.target.value)
    const newSearch = e.target.value
    const filtered = listOfProducts.filter(product => {
      return (
        product.title.toLowerCase().includes(newSearch.toLowerCase()) ||
        product.productType.toLowerCase().includes(newSearch.toLowerCase())
      )
    })
    setList(filtered)
  }

  function sortType(e) {
    const newSelect = e.target.value
    setSelect(newSelect)

    if (newSelect === "all") {
      setList(listOfProducts)
    } else {
      let filteredByType = listOfProducts.filter(product => {
        return product.productType === newSelect
      })

      setList(filteredByType)
    }
  }

  return { handleChange, list, search, types, sortType, select }
}

import { useState, useEffect } from "react"
import { useStore } from "../context/TestContext"

export function useShoppingCart() {
  const { state, toggleCart, addCoupon, removeCoupon } = useStore()

  const { cart, checkout, isCartOpen, total, bundles } = state
  // console.log("bundles:", bundles)

  let realTotal = getRealTotal({ checkout, total })
  // console.log("realTotal:", realTotal)

  function getRealTotal({ checkout, total }) {
    const intTotal = parseFloat(total)
    const amountV1 = parseFloat(checkout?.subtotalPrice)

    const amountV2 = parseFloat(checkout?.subtotalPriceV2?.amount)

    if (intTotal === amountV2 || intTotal === amountV1) {
      return intTotal.toFixed(2)
    }

    if (
      JSON.stringify(amountV1) === "null" ||
      JSON.stringify(amountV2) === "null"
    ) {
      return intTotal.toFixed(2)
    }

    return amountV2.toFixed(2)
  }

  // console.log("FIXED HERE ", getRealTotal({ checkout, total }))

  // total === 0
  // ? total.toFixed(2)
  // : total === checkout?.subtotalPriceV2?.amount
  // ? total.toFixed(2)
  // : checkout?.subtotalPriceV2?.amount?.toFixed(2) ||
  //   checkout?.subtotalPrice?.toFixed(2)

  // console.log("realTotal:", realTotal)

  const [coupon, setCoupon] = useState("")
  const [message, setMessage] = useState("")
  const [onError, setOnError] = useState("")

  async function setError({ msg, url }) {
    await navigator.clipboard.writeText(url)
    updateUserWithMessage({ msg, stateUpdater: setOnError, time: 15000 })
  }

  async function setDiscount(e) {
    e.preventDefault()

    const working = await addCoupon(coupon)

    if (working.applied) {
      setCoupon("")
      return updateUserWithMessage({
        msg: working.msg,
        stateUpdater: setMessage,
      })
    }

    return updateUserWithMessage({
      msg: working.msg,
      stateUpdater: setMessage,
    })
  }

  function updateUserWithMessage({ msg, stateUpdater, time = 3000 }) {
    stateUpdater(msg)

    if (!time) {
      return
    }
    setTimeout(() => {
      stateUpdater("")
    }, time)
  }

  function handleCoupon(e) {
    setCoupon(e.target.value)
  }

  const isCartEmpty =
    cart.length === 0 &&
    Object.keys(bundles).length === 0 &&
    bundles.constructor === Object

  function paymentCheckout() {
    const url = checkout?.webUrl
    let x = null
    if (isCartEmpty) {
      return
    } else {
      try {
        x = window.open(url)
      } catch (error) {
        console.log("error:", error)
      } finally {
        if (!x) {
          const msg = `There was an error in redirecting you to the checkout page. We added the link of the checkout to your clipboard.`
          return setError({ msg, url })
        }
      }
    }
  }

  const discountInfo = checkout?.discountApplications?.[0]
  const discountCode = discountInfo?.code
  const isShipping = discountInfo?.targetType === "SHIPPING_LINE"

  return {
    isCartOpen,
    setDiscount,
    paymentCheckout,
    toggleCart,
    cart,
    discountCode,
    removeCoupon,
    discountInfo,
    handleCoupon,
    coupon,
    message,
    paymentCheckout,
    isShipping,
    state,
    total: realTotal,
    onError,
    url: checkout?.webUrl,
    isCartEmpty,
    bundles,
  }
}
